@font-face {
  font-family: "Futura";
  src: url("./assets/font/futura/futura\ medium\ bt.ttf") format("woff2"),
    /* Modern Browsers */ url("./assets/font/futura/futura\ medium\ bt.ttf")
      format("woff"); /* Older Browsers */
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* font-family: "Urbanist", sans-serif; */
  font-family: "Futura";
  font-optical-sizing: auto;
  font-style: normal;
}

body {
  background-color: #fff;
}

.main-section {
  background-color: #1b081d !important;
}

.app {
  transition: background-color 0.3s ease;
  /* Optional: add a smooth transition effect */
}

.scrolling header {
  /* background: #1b081d; */
}

#header {
  transition: all 0.5s;
  z-index: 997;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}

.navbar.navbar-light {
  position: fixed;
  top: 4%;
  left: 0;
  right: 0;
  z-index: 9999;
  /* background-color: rgba(255, 255, 255, 0) !important; */
}
.navbar-scrolled {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.app .Toastify {
  position: relative;
  z-index: 99999;
}

/* .navbar .navbar-toggler{
  background-color: #c7afc6;
} */
.navbar-toggler:focus {
  box-shadow: none !important;
}

/* modal */
@media (max-width: 767px) {
  body.modal-open {
    overflow: auto !important;
  }
  div[role="dialog"] {
    position: absolute;
    height: auto;
  }
}
