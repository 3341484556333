.cart-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.row-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-page .row-content .d-block .pricingplan-img {
  height: 90px;
  width: auto;
}
.cart {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;
}
.plan .title,
.plan .sub-total,
.plan .tax,
.plan .total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-wave-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.4;
}

.bottom-wave-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0.4;
}
